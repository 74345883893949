import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Header from '../components/Header'
import Navigation from 'components/Navigation/Navigation'
import NavigationMobile from '../components/Navigation/NavigationMobile'
import SEO from 'components/SEO'
import { Container, Heading } from 'unstyled'
import { ThemeProvider } from 'styled-components'
import theme from '../theme/index'

const Content = styled.div`
  color: white;
  text-align: center;
  position: relative;
  background-color: #333;
  max-width: 33vw;
  margin: auto;
  opacity: 0.75;
  padding: 1rem;
  top: 3rem;

  @media (max-width: 680px) {
    max-width: 100%;
  }
`

const IndexPage = props => {
  const {
    data: {
      file: { childImageSharp: background },
    },
  } = props
  return (
    <ThemeProvider theme={theme}>
      <Element>
        <SEO />
        <Background fluid={background.fluid} />
        <Header background={false} />
        <Navigation inverted />
        <Container>
          <Content>
            <Heading color="#ba2c73">
              Willkommen in der Julius-Klengel-Akademie
            </Heading>

            <p>
              Schüler, Studenten, Eltern, Kinder, Profis, Amateure, Geiger,
              Cellisten – hier gibt es für jeden etwas zu entdecken!
            </p>

            <p>
              Die Julius-Klengel-Akademie wurde 2010 anlässlich des 150.
              Geburtstages von Julius Klengel, dem berühmten Leipziger Cellisten
              und Pädagogen, mit einem Festkonzert eröffnet.
            </p>

            <p>
              Seitdem erhalten hier Schüler jeden Alters ihre Ausbildung auf der
              Violine oder dem Violoncello. In einer der außergewöhnlichen
              Herrmannnschen Villen in der Leipziger Südvorstadt unterrichtet
              unser Team von erfahrenen Musikern in familiärer Athmosphäre.
            </p>
            <NavigationMobile />
          </Content>
        </Container>
      </Element>
    </ThemeProvider>
  )
}

export default IndexPage

export const query = graphql`
  query Background {
    file(relativePath: { eq: "background.jpg" }) {
      id
      childImageSharp {
        id
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Element = styled.div``
const Background = styled(Img)`
  position: fixed !important;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  z-index: -1;
`
